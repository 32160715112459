import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { finalize } from 'rxjs/operators';
@Injectable()
export class HttpsInterceptor implements HttpInterceptor {
  constructor(private router: Router, @Inject(PLATFORM_ID) private platformId: any) { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
  //  this.spinner.show();
    // console.log("request url :",req.url);
    if (!req.url.includes('netEnquiriesFromMiles')) {
      if (isPlatformBrowser(this.platformId)) {
        if (req.url.includes('wileyNXT_post_website')) {
          const userToken = localStorage.getItem('authtoken');
          req = req.clone({
            setHeaders: {
              'x-access-token': `${userToken}`,
              'Content-Type': 'application/json',
              'Access-Control-Allow-Credentials': '*',

            }
          });
        } else if (req.url.includes('_payment')) {
          // console.log('in payment');
          req = req.clone({
            withCredentials: true
          });
        }
        else if((req.url.includes('gameplan'))||(req.url.includes('student'))||(req.url.includes('submit_eligibility'))){
          const userToken = localStorage.getItem('gptoken');
          // console.log('in else', req.url);
          req = req.clone({
            setHeaders: {
              'x-access-token': `${userToken}`,
              'Content-Type': 'application/json',
            }
          });
        }
        else {
          const userToken = localStorage.getItem('authtoken');
          // console.log('in else', req.url);
          req = req.clone({
            setHeaders: {
              'x-access-token': `${userToken}`,
              'Content-Type': 'application/json'}
          });

        }

      }
    } else {
      req = req.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImJmYmYwNTcxODNjMmE4Y2EyNDI4NDQ0YzdlNDUzZTVmN2E3MTI0MWQ1MDAwMWM5ZWJmYmQ5ZDA5YWMxMzQyYTZhMTU0ZjFmN2Q2YmIwZmFlIn0.eyJhdWQiOiIxIiwianRpIjoiYmZiZjA1NzE4M2MyYThjYTI0Mjg0NDRjN2U0NTNlNWY3YTcxMjQxZDUwMDAxYzllYmZiZDlkMDlhYzEzNDJhNmExNTRmMWY3ZDZiYjBmYWUiLCJpYXQiOjE1Njc1ODc3ODUsIm5iZiI6MTU2NzU4Nzc4NSwiZXhwIjoxNTk5MjEwMTg1LCJzdWIiOiIxNjgiLCJzY29wZXMiOltdfQ.Nq_3wZSOFHz52xR8eDZVqSxUlxY_ECZvnCweGYhCWviFyQy8BjSAil7A5aL27aiQ-E_2t5f4yooN2ak0ILSmdTj3obaSc18oq38TAExu6JHXbf_Bhp21jqueI4GplH8ZXNvZenu_vH6ZHxZpnl7lnVgJyoZgiukARaiXxYGWDoYcxowjw4DQq4e_jrooY42tkpFrXv0zVRcwb3KZ7o1KzLNv4R1UuESXJKoC3ZKweuuS8NOxhyTpN7BMKzceRs6qqFnsXIiedq50TVoX1WJr6pjHxBSv9Dkr9uRc2HX1Vzt3AwunnaD-T4w3pIPj7CaMcjah7AgjVMnSqpZlPb0BcaB7hUOErIQB7yz10HHtShSSYwmTORtEkdGRef2gdW_xf7BynBHEUJyrs-wmYKVg47beksjvTz97IbbOBcD6TBi4fKmCNIbLtTMO-xtq9LGyKfCux0Yn6zFzW39m2_D_EILOOXYLqCePxOQxqi_d4ZQSSsQPw-1gifcl6VewerpXhgPtRAyFHSofY9cPPBEfxGyAxsmrYRF6Vka1pdqjYo_awitZTlHM9w7utyJunLTdsFLxDdFa8Y1cGOzN03HdTTqI0XPdqaa3HVXR8D0Nw7YRNAQ0O5VXIQiDqU79pmlnC7WPrtdKgsk3ffZaZgb1lcfLtVxuiqemhAF-mdVooR8'
        }
      });
    }

    return next.handle(req).pipe(finalize(() => {
       // this.spinner.hide();
    }))
      // .pipe(
      //   timeout(10000),
      //   catchError((error: HttpErrorResponse) => {
      //     let errorMessage = '';
      //     if (error.status === 403) {
      //       this.router.navigate(['/analytics/iim-lucknow/application']);
      //     }
      //     if (error.error instanceof ErrorEvent) {
      //       // client-side error
      //       errorMessage = `Error: ${error.error.message}`;
      //     }
      //     return throwError(errorMessage);
      //   }),
      //   finalize(() => { }),
      // );
  }
}
export const httpInterceptorProviders = [{
  provide: HTTP_INTERCEPTORS,
  useClass: HttpsInterceptor,
  multi: true}];
